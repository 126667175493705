<template>
  <v-dialog
    v-model="dialog"
    eager
    fullscreen
    hide-overlay
    min-height
    scrollable
    content-class="overflow-x-hidden"
  >
    <BatteryReadings
      v-if="id"
      :id="id"
      :showCloseButton="dialog"
      @closed="closeDialog()"
    />
  </v-dialog>
</template>

<script>
import BatteryReadings from "@/components/batteries/BatteryReadings.vue"

export default {
  components: { BatteryReadings },
  props: {
    id: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.showDialog
      },
      set() {
        this.$emit("closed", false)
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit("closed", true)
    },
  },
}
</script>
