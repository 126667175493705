<template>
  <v-card
    v-if="cameraDialogStore.camera.exid"
    :flat="inline"
    :loading="cameraDialogStore.loading"
    class="camera-details__content overflow-hidden"
    tile
  >
    <v-card-title class="pa-0 pl-2 camera-details__header">
      <CameraDialogHeader
        v-if="isCameraSelected"
        :camera="cameraDialogStore.camera"
        :inline="inline"
        @close="$emit('close')"
      />
    </v-card-title>
    <v-divider />

    <v-container class="pa-0 ma-0 overflow e-max-h-screen e-h-full" fluid>
      <ETabs
        v-model="cameraDialogStore.tab"
        active-class="blue lighten-5"
        class="camera-details__tabs custom-tabs"
        :vertical="$vuetify.breakpoint.mdAndUp"
        @tab-selected="onTabChange"
      >
        <Summary name="Summary" />
        <SnapshotExtractions
          :exid="cameraDialogStore.camera.exid"
          name="Extractions"
        />
        <BrainTool name="BrainTool" />
        <Demo :camera="camera" name="Demo" />
        <Milestones name="Milestones" />
        <DangerZone name="Danger Zone" />
      </ETabs>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import CameraDialogHeader from "@/components/cameraDialog/CameraDialogHeader"
import Summary from "@/components/cameraTabs/Summary"
import SnapshotExtractions from "@/components/cameraTabs/SnapshotExtractions"
import Demo from "@/components/cameraTabs/Demo"
import BrainTool from "@/components/cameraTabs/BrainTool.vue"
import DangerZone from "@/components/cameraTabs/DangerZone"
import Milestones from "@/components/cameraTabs/Milestones.vue"
import { mapStores } from "pinia"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { useLayoutStore } from "@/stores/layout"

export default {
  name: "CameraDialogContent",
  components: {
    Summary,
    CameraDialogHeader,
    SnapshotExtractions,
    BrainTool,
    Demo,
    DangerZone,
    Milestones,
  },
  props: {
    /**
     * Can be either a camera object, or a String
     * In case of a string (camera exid),
     * the camera details are going to be fetched from server
     */
    camera: {
      type: [String, Object],
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCameraSelected: false,
    }
  },
  computed: {
    ...mapStores(useCameraDialogStore, useLayoutStore),
    simNumber(): string {
      return this.cameraDialogStore.camera?.simNumber
        ? this.cameraDialogStore.camera.simNumber
        : ""
    },
  },
  watch: {
    camera: {
      immediate: true,
      async handler(cam = {}) {
        const exid = typeof cam === "object" ? cam?.exid : cam
        if (exid) {
          this.isCameraSelected = false
          await this.cameraDialogStore.selectCamera(exid)
          this.isCameraSelected = true
        }
      },
    },
  },
  methods: {
    onTabChange(tab) {
      this.updateSubQuery({ subQueryObj: { tab } })
    },
  },
}
</script>

<style lang="scss">
@import "~vuetify/src/styles/styles.sass";
@import "assets/vars";

.camera-details {
  overflow: hidden;

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    max-width: calc(100vw - #{$sidebar-width});
    margin-left: $sidebar-width !important;
  }

  &__header {
    box-shadow: 0px 5px 12px -13px #0000007a;
    z-index: 1;
  }

  &__content {
    overflow: hidden;
  }

  &__tabs {
    & > .v-tabs-bar {
      border-right: 1px solid #e0e0e0;
      box-shadow: 5px 0px 11px -12px #000000b5;
      z-index: 1;
    }
    & > .v-tabs-items {
      overflow-y: auto;
      max-height: calc(100vh - 57px);
      height: calc(100vh - 57px);
    }
    .v-tab {
      text-transform: none;
    }

    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      .v-tabs-bar {
        border-bottom: thin solid #e0e0e0;
      }
    }
  }

  &--fullscreen {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: calc(100vw - #{$sidebar-width--sm});
      margin-left: $sidebar-width--sm !important;
    }
  }

  &--fullscreen-no-side {
    position: relative;
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      max-width: 100%;
      margin-left: 0 !important;
    }
  }
}

.camera-tab-fullscreen {
  display: none;
}
</style>
