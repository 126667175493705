<template>
  <div>
    <CameraDialogContent
      v-if="inline"
      class="camera-details"
      :camera="camera"
      :inline="inline"
    />
    <v-dialog
      v-else
      v-model="cameraDialogStore.dialog"
      eager
      fullscreen
      hide-overlay
      scrollable
      persistent
      transition="fade-transition"
      class="camera-details"
      :content-class="`camera-details ${cameraScreenSize}`"
      no-click-animation
      @keydown.esc="$emit('close')"
    >
      <CameraDialogContent :camera="camera" @close="$emit('close')" />
    </v-dialog>
  </div>
</template>

<script>
import CameraDialogContent from "@/components/cameraDialog/CameraDialogContent"
import { useCameraDialogStore } from "@/stores/cameraDialog"
import { mapStores } from "pinia"

export default {
  components: {
    CameraDialogContent,
  },
  props: {
    /**
     * Can be either a camera object, or a String
     * In case of a string (camera exid),
     * the camera details are going to be fetched from server
     */
    camera: {
      type: [String, Object],
      required: true,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapStores(useCameraDialogStore),
    cameraScreenSize() {
      if (this.cameraDialogStore.hideSidebar) {
        return "camera-details--fullscreen-no-side"
      }

      if (this.cameraDialogStore.tabFullScreen) {
        return "camera-details--fullscreen"
      }

      return ""
    },
  },
  watch: {
    "cameraDialogStore.dialog"(value) {
      if (!value) {
        this.loading = false
      }
    },
  },
}
</script>
