export default [
  {
    value: "datetime",
    text: "Reading At",
    sortable: true,
    visible: true,
    width: 140,
  },
  {
    value: "voltage",
    text: "Battery voltage (V)",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "iValue",
    text: "Battery current (A)",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "vpvValue",
    text: "PanelVoltage (V)",
    sortable: true,
    visible: true,
    width: 120,
    align: "center",
  },
  {
    value: "ppvValue",
    text: "Panel power (W)",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "serialNo",
    text: "Serial#",
    sortable: true,
    visible: true,
    width: 120,
  },
  {
    value: "csValue",
    text: "State of operation",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "errValue",
    text: "Error code",
    sortable: true,
    visible: true,
    width: 60,
    align: "center",
  },
  {
    value: "ilValue",
    text: "Load current (A)",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "mpptValue",
    text: "MPPT",
    sortable: true,
    visible: true,
    width: 70,
    align: "center",
  },
  {
    value: "loadValue",
    text: "Load",
    sortable: true,
    visible: true,
    width: 60,
    align: "center",
  },
  {
    value: "h19Value",
    text: "Yield total (Wh)",
    sortable: true,
    visible: true,
    width: 100,
    align: "center",
  },
  {
    value: "h20Value",
    text: "Yield today (Wh)",
    sortable: true,
    visible: true,
    width: 100,
    align: "center",
  },
  {
    value: "h21Value",
    text: "Maximum power today (W)",
    sortable: true,
    visible: true,
    width: 120,
    align: "center",
  },
  {
    value: "h22Value",
    text: "Yield yesterday (W)",
    sortable: true,
    visible: true,
    width: 110,
    align: "center",
  },
  {
    value: "h23Value",
    text: "Maximum power yesterday (W)",
    sortable: true,
    visible: true,
    width: 130,
    align: "center",
  },
  {
    value: "consumedAmphours",
    text: "Consumed amphours",
    sortable: true,
    visible: true,
    width: 100,
    align: "center",
  },
  {
    value: "socValue",
    text: "Soc",
    sortable: true,
    visible: true,
    width: 60,
    align: "center",
  },
  {
    value: "timeToGo",
    text: "Time To Go",
    sortable: true,
    visible: true,
    width: 65,
    align: "center",
  },
  {
    value: "alarm",
    text: "Alarm",
    sortable: true,
    visible: true,
    width: 65,
    align: "center",
  },
  {
    value: "relay",
    text: "Relay",
    sortable: true,
    visible: true,
    width: 65,
    align: "center",
  },
  {
    value: "h1Value",
    text: "Deepest Discharge",
    sortable: true,
    visible: true,
    width: 80,
    align: "center",
  },
  {
    value: "h2Value",
    text: "Last Discharge",
    sortable: true,
    visible: true,
    width: 80,
    align: "center",
  },
  {
    value: "h3Value",
    text: "Average Discharge",
    sortable: true,
    visible: true,
    width: 80,
    align: "center",
  },
  {
    value: "h4Value",
    text: "Charge Cycles",
    sortable: true,
    visible: true,
    width: 80,
    align: "center",
  },
  {
    value: "h5Value",
    text: "Full Discharges",
    sortable: true,
    visible: true,
    width: 90,
    align: "center",
  },
  {
    value: "h6Value",
    text: "Total Drawn",
    sortable: true,
    visible: true,
    width: 65,
    align: "center",
  },
  {
    value: "h7Value",
    text: "Minimum Voltage",
    sortable: true,
    visible: true,
    width: 75,
    align: "center",
  },
  {
    value: "h8Value",
    text: "Maximum Voltage",
    sortable: true,
    visible: true,
    width: 75,
    align: "center",
  },
  {
    value: "h9Value",
    text: "Time Since Last Full Charge",
    sortable: true,
    visible: true,
    width: 125,
    align: "center",
  },
  {
    value: "h10Value",
    text: "Automatic Syncs",
    sortable: true,
    visible: true,
    width: 85,
    align: "center",
  },
  {
    value: "h11Value",
    text: "Low Voltage Alarms",
    sortable: true,
    visible: true,
    width: 100,
    align: "center",
  },
]
